import styled from "@emotion/styled";
import { calcRem } from "@the-game/components/utils";
export const StyledCompetitionHeader = styled("header")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: calcRem(theme.spacing.xl),
  marginBottom: calcRem(theme.spacing.xl),
  borderBottom: `1px solid ${theme.colors.palette.neutral50}`,
  h1: {
    marginBottom: calcRem(theme.spacing.xl),
    textAlign: "center"
  },
  a: {
    marginBottom: calcRem(theme.spacing.md),
    alignSelf: "flex-start"
  }
}));
