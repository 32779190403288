import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

const Spacer = styled.div`
    display: flex;
    justify-content: center;
`

const SelectorContainer = styled.div<{ primaryColor: string }>`
    display: flex;
    justify-content: center;
    flex-direction: row;
    border: 2px solid ${(props) => props.primaryColor};
    border-radius: 4px;
    overflow: hidden;
    font-size: 14px;
    font-weight: 400;
`

const SelectorItem = styled.button<{ active: boolean; primaryColor: string }>`
    cursor: pointer;
    width: 172px;
    height: 32px;
    border-radius: none;
    border: none;
    background: ${(props) => (props.active ? props.primaryColor : 'none')};
    color: ${(props) => (props.active ? 'white' : props.primaryColor)};
    transition: 0.1s;
`

export function StateSelector({
    values,
    current,
    onChange,
    leaderboardStatePressed,
    sport,
    season,
}: {
    values: string[]
    current: string
    onChange: (arg: string) => void
    leaderboardStatePressed: (
        state: string,
        sport: string,
        season: string,
    ) => void
    sport: string
    season: string
}) {
    const theme = useTheme()

    return (
        <Spacer>
            <SelectorContainer primaryColor={theme.colors.primary}>
                {values.map((value) => {
                    return (
                        <SelectorItem
                            key={value}
                            title={value}
                            active={current === value}
                            primaryColor={theme.colors.primary}
                            onClick={() => {
                                onChange(value)
                                leaderboardStatePressed(value, sport, season)
                            }}
                        >
                            {value}
                        </SelectorItem>
                    )
                })}
            </SelectorContainer>
        </Spacer>
    )
}
